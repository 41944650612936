import React from "react";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import HeroSectionComponent from "../components/HeroSectionComponent";
import ContentWithSideBar from "../components/ContentWithSideBar";
import Sidebar from "../components/Sidebar";
import BlockLatestProductList from "../components/BlockLatestProductList";
import Contentwithoutsb from "../components/Contentwithoutsb";
import ProductRange from "../components/ProductRange";
import Seo from "../components/SEO";

const StaticTextWrapper = styled.div`
  display: flex;
  flex: 1;
  text-align: center;
  margin-bottom: 5rem;

  @media (min-width: 768px) {
    text-align: left;
    padding-right: 6rem;
  }

  p {
    font-size: 1.8rem;
  }

  strong {
    font-family: var(--font-quattrocento-bold);
  }
`;

export default function AboutUs({ data }) {
  const {
    hero_title,
    hero_text,
    hero_image,
    hero_background_image,
    _rawContentText,
  } = data.about.nodes[0];

  return (
    <>
      <Seo
        title={hero_title}
        description={hero_text}
        image={hero_image?.asset?.url}
        location={useLocation()}
      />
      <HeroSectionComponent
        title={hero_title}
        text={hero_text}
        heroImage={false}
        heroBackgroundImage={hero_background_image.asset.gatsbyImageData}
      />
      <ContentWithSideBar>
        <StaticTextWrapper>
          <BlockContent blocks={_rawContentText} />
        </StaticTextWrapper>
        <Sidebar />
      </ContentWithSideBar>
      <BlockLatestProductList
        title='latest products'
        link='/our-products'
        linkText='view all products'
        borderColor='var(--grey-light)'
        smallVersion={false}
      />
      <Contentwithoutsb>
        <ProductRange />
      </Contentwithoutsb>
    </>
  );
}

export const query = graphql`
  query AboutUsQuery {
    about: allSanityAboutPage {
      nodes {
        hero_title
        hero_text
        hero_image {
          asset {
            url
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }

        hero_background_image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }

        _rawContentText(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
